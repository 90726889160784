import { createReducer } from "@reduxjs/toolkit";

import { getErrorLogSuccess } from "./errorLogAction";

export interface IEmailHistoryState {
  errorLog?: Array<any>;
  dataCount: number;
  isLoading?: boolean;
}
const initialState: IEmailHistoryState = {
  errorLog: [],
  dataCount: 0,
  isLoading: false,
};
export const ErrorLogReducer = createReducer(initialState, (builder) => {
  builder.addCase(getErrorLogSuccess, (state, action) => {
    state = {
      ...state,
      errorLog: action.payload?.data,
      dataCount: action.payload?.dataCount,
    };
    return state;
  });
});
