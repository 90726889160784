import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import downTimeReasonDataAccess from "src/dataAccess/downTimeReason/downTimeReasonDataAccess";

export const getDownTimeReasonListSuccess = createAction<any>(
  "[DownTimeReason] Get DownTimeReasin List Success"
);
export const loadingList = createAction<boolean>(
  "[DownTimeReason] Loading list"
);

export const getDownTimeListAsync = createAsyncThunk<any, IListType>(
  "[DownTimeReason] Get DownTimeReason List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await downTimeReasonDataAccess
      .downTimeReasonList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getDownTimeReasonListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
export const saveDownTimeReasonListAsync = createAsyncThunk<
  any,
  { downTimeReasonId: any; data: any; downTimeReasonList: Array<any> }
>("[DownTimeReason] Save Down Time Reason", async (parameter, thunkAPI) => {
  const { downTimeReasonId, data, downTimeReasonList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  console.log(downTimeReasonId);
  var updatedDownTimeReasonList;
  if (downTimeReasonId == "edit") {
    let copyDownTImeReasonList = [...downTimeReasonList];
    updatedDownTimeReasonList = (copyDownTImeReasonList ?? []).map(
      (item, index) => {
        let copyItem = { ...item };

        if (copyItem.id == data.id) {
          copyItem = data;
        }
        return copyItem;
      }
    );
  } else {
    let copyDownTImeReasonList = [...downTimeReasonList];
    updatedDownTimeReasonList = [...copyDownTImeReasonList, data];
  }
  thunkAPI.dispatch(saveDownTimeReasonListSuccess(updatedDownTimeReasonList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});
export const saveDownTimeReasonListSuccess = createAction<any>(
  "[DownTimeReason] Save DownTimeReason Success"
);
