import { createReducer } from "@reduxjs/toolkit";
import {
  GetEmailHistoryList,getEamilHistoryListSuccess } from "./emailHistoryAction";
import { get } from "http";
  export interface IEmailHistoryState {
    emailHistoryList?: Array<any>;
    dataCount: number;
    isLoading?: boolean;
  }
  const initialState: IEmailHistoryState = {
    emailHistoryList: [],
    dataCount: 0,
    isLoading: false,
  };
  export const EmailHistoryReducer = createReducer(initialState, (builder) => {
    builder
      
      .addCase(getEamilHistoryListSuccess, (state, action) => {
        state = {
          ...state,
          emailHistoryList: action.payload?.data,
          dataCount: action.payload?.dataCount,
        };
        return state;
     })
    });
  