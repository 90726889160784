import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import EmailHistoryDataAccess from "src/dataAccess/EmailHistory/emailHistroyDataAccess";
import { IListType } from "../store";
import { createAbstractBuilder } from "typescript";
export const getEamilHistoryListSuccess = createAction<any>(
    "[Email History] Get Email History List Success"
  );
  export const loadingList = createAction<boolean>("[EmailHistroy] Loading list");
  export const GetEmailHistoryList = createAsyncThunk<any, IListType>(
    "[EmailHistory] Get Email History List",
    async (parameter, thunkAPI) => {
      thunkAPI.dispatch(loadingList(true));
      const result = await EmailHistoryDataAccess.getList(parameter).then((res) => {
        console.log(res.data);
        thunkAPI.dispatch(getEamilHistoryListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
      return result;
    }
  );