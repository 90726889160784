import { AxiosError, AxiosResponse } from "axios";
import { localStorageKeys } from "../common/constants";
import Axios from "./axios-middleware";
// import * as stream from "stream";

const { apiUrl, legacyUrl, apiPrefix } = window as any;

interface IdownloadParams {
  url: string;
  payload: Object;
  params: Object;
  onDowloadProgress: (progress: any) => void;
  type: string;
}

class commonDataAccess {
  getData = (url: string, params: Object = {}) => {
    return Axios.get(apiUrl + apiPrefix + url, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
      },
      params: params,
    })
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        return error;
      });
  };

  putData = (url: string, data: Object = {}, params: Object = {}) => {
    return Axios.put(apiUrl + apiPrefix + url, data, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status?.toString() === "401") {
          // commonActions.onUnauthorize();
          // localStorage.clear();
          // window.location.reload();
        } else {
          throw new Error(error.message);
        }
      });
  };
  postData = (url: string, data: Object = {}, params: Object = {}, urlType: string = null) => {
    const setUrl = urlType === "Legacy" ?
    legacyUrl + url : apiUrl + apiPrefix + url;
    return Axios.post(setUrl, data, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        console.log(error, "error");
        if (error?.response?.status?.toString() === "401") {
          // commonActions.onUnauthorize();
          // localStorage.clear();
          // window.location.reload();
        } else {
          throw new Error(error.message);
        }
      });
  };

  
  downloadPost = (parameters: Partial<IdownloadParams>) => {
    const url = parameters?.type === "Legacy" ?
     legacyUrl + parameters?.url : apiUrl + apiPrefix + parameters?.url;
    return Axios({
      url: url,
      data: parameters?.payload,
      onDownloadProgress(progressEvent) {
        console.log(progressEvent);
        let progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        parameters.onDowloadProgress(progress);
      },
      method: "POST",
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
      },
      responseType: "blob",
      params: parameters?.params,
    })
      .then((response) => {
        const type = response.headers["content-type"];

        let blob = new Blob([response.data], {
          type: type,
        });
        let url = URL.createObjectURL(blob);
        return url;
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status?.toString() === "401") {
          // commonActions.onUnauthorize();
        } else {
          // throw new Error(error.message);
        }
      });
    // return Axios.post(apiUrl + apiPrefix + url, data, {
    //   headers: {
    //     Authorization: `bearer ${localStorage.getItem(
    //       localStorageKeys.authorization
    //     )}`,
    //   },
    //   responseType: "blob",
    //   params: params,
    // })
    //   .then((response) => {
    //     const type = response.headers["content-type"];

    //     let blob = new Blob([response.data], {
    //       type: type,
    //     });
    //     let url = URL.createObjectURL(blob);
    //     return url;
    //   })
    //   .catch((error: AxiosError) => {
    //     if (error.response.status.toString() === "401") {
    //       // commonActions.onUnauthorize();
    //     } else {
    //       // throw new Error(error.message);
    //     }
    //   });
  };

  downloadFile = (url: string, params: Object = {}, type: String = "") => {
    const Url = type === "Legacy" ?
     legacyUrl + url : apiUrl + apiPrefix + url;
    return Axios.get(Url, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
      },
      responseType: "blob",
      params: params,
    })
      .then((response) => {
        const type = response.headers["content-type"];
        let blob = new Blob([response.data], {
          type: type,
        });
        let url = URL.createObjectURL(blob);

        return url;
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status?.toString() === "401") {
          // commonActions.onUnauthorize();
        } else {
          // throw new Error(error.message);
        }
      });
  };


  downloadFileByPost = (url: string, payload: any, params: Object = {}) => {
    return Axios.post(apiUrl + apiPrefix + url, payload, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
      },
      responseType: "blob",
      params: params,
    })
      .then((response) => {
        const type = response.headers["content-type"];

        let blob = new Blob([response.data], {
          type: type,
        });
        let url = URL.createObjectURL(blob);
        return url;
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status?.toString() === "401") {
          // commonActions.onUnauthorize();
        } else {
          // throw new Error(error.message);
        }
      });
  };

  postFormData = (url: string, formData: FormData, params: Object = {}) => {
    return Axios.post(apiUrl + apiPrefix + url, formData, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
        "content-type": "multipart/form-data",
      },
      params: params,
    }).then((res) => res?.data);
  };
  postDataForFile = (url: string, data: Object = {}, params: Object = {}) => {
    return Axios.post(apiUrl + apiPrefix + url, data, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
      },
      responseType: "blob",
      params: params,
    })
      .then((response: AxiosResponse) => {
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status?.toString() === "401") {
          // commonActions.onUnauthorize();
          localStorage.clear();
          window.location.reload();
        } else {
          throw new Error(error.message);
        }
      });
  };

  postDataNoResponse = (
    url: string,
    data: Object = {},
    params: Object = {}
  ) => {
    return Axios.post(apiUrl + apiPrefix + url, data, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
      },
      params: params,
    })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status?.toString() === "401") {
          // commonActions.onUnauthorize();
          // localStorage.clear();
          // window.location.reload();
        } else {
          throw new Error(error.message);
        }
      });
  };
  deleteData = (url: string, params: Object = {}) => {
    return Axios.delete(apiUrl + apiPrefix + url, {
      headers: {
        Authorization: `bearer ${localStorage.getItem(
          localStorageKeys.authorization
        )}`,
      },
      params: params,
    })
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        return error;
      });
  };
}
export default new commonDataAccess();
