import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import recipeprocessDataAccess from "src/dataAccess/recipeProcess/recipeprocessDataAccess";

export const loadingList = createAction<boolean>(
  "[RecipeProcess] Loading list"
);
export const getRecipeProcessListAsync = createAsyncThunk<any, IListType>(
  "[RecipeProcess] Get RecipeProcess List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await recipeprocessDataAccess
      .RecipeProcessList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getRecipeProcessListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
export const getRecipeProcessListSuccess = createAction<any>(
  "[RecipeProcess] Get RecipeProcess List Success"
);
