import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import incidentDataAccess from "src/dataAccess/incidents/incidentDataAccess";
import salesOrderDataAcess from "src/dataAccess/salesOrders/salesOrderDataAcess";

export const getSalesOrderListSuccess = createAction<any>(
  "[Sales Order] Get Sales Order List Success"
);
export const loadingList = createAction<boolean>("[Sales Order] Loading list");

export const UpdateSODetails = createAction<any>("Update SODetails Success");
export const AddSODetails = createAction<any>("Add SODetails Success");
export const RemoveSODetails = createAction<any>("Remove SODetails Success");
export const SetSODetails = createAction<any[]>("[SO] set PODetails");
export const getSalesOrderListAsync = createAsyncThunk<any, IListType>(
  "[Sales Order] Get sales Order List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await salesOrderDataAcess
      .salesOrderList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getSalesOrderListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);

export const saveSalesOrderListSuccess = createAction<any>(
  "[Sales Order] Save Sales Order Success"
);

// export const saveSalesOrderListAsync = createAsyncThunk<
//   any,
//   { salesOrderId: any; data: any; sales: Array<any> }
// >("[Sales Order] Save Sales Order", async (parameter, thunkAPI) => {
//   const { incidentId, data, salesOrderList } = parameter;
//   thunkAPI.dispatch(loadingList(true));
//   var updatedIncidentList;
//   if (incidentId == "edit") {
//     let copyIncidentList = [...salesOrderList];
//     updatedIncidentList = (copyIncidentList ?? []).map((item, index) => {
//       let copyItem = { ...item };

//       if (copyItem.id == data.id) {
//         copyItem = data;
//       }
//       return copyItem;
//     });
//   } else {
//     let copyIncidentList = [...incidentList];
//     updatedIncidentList = [...copyIncidentList, data];
//   }
//   thunkAPI.dispatch(saveSalesOrderListSuccess(updatedIncidentList));
//   thunkAPI.dispatch(loadingList(false));
//   return data;
// });
