import { Image } from "antd";
import { useState } from "react";

export const PreviewUploaderImage = (props) => {
  const [visible, setVisible] = useState(true);
  return (
    <>
      <Image
        //{...restProps}
        style={{ display: "none" }}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value);
            props.close();
          },
          src: props.data.imageData,
        }}
      />
    </>
  );
};
