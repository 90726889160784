import { createReducer } from "@reduxjs/toolkit";
import {
  AddPurchaseOrderDeletionReasonSuccess,
  GetAuditsettingsSuccess,
  GetChecksettingsSuccess,
  GetCompanyDetailsSettingsSuccess,
  GetComplaintSettingsSuccess,
  GetDispatchSettingsSuccess,
  GetDocumentationSettingsSuccess,
  GetEmailSettingsSuccess,
  GetExportPathSettingsSuccess,
  GetFilterSettingsSuccess,
  GetGoodsInSettingsSuccess,
  GetLabourSettingsSuccess,
  GetLogoutSettingsSuccess,
  GetNonConformancesSettingsSuccess,
  GetPortableDeviceSyncSuccess,
  GetPrintingSettingsSuccess,
  GetProductStockAndFilterSuccess,
  GetPurchaseOrderDeletionReasonSuccess,
  GetPurchaseOrderSettingsSuccess,
  GetRecipesSettingsSuccess,
  GetReportSettingsSuccess,
  GetSalesOrderSettingsSuccess,
  GetStockCheckSettingsSuccess,
  GetSupplierSettingsSuccess,
  GetTabletSettingsSuccess,
  GetTrainingSettingsSuccess,
  GetVisitorSettingsSuccess,
  GetWorkRequestNumberSuccess,
  LoadingData,
  SaveAuditsettingsSuccess,
  SaveChecksettingsSuccess,
  SaveCompanyDetailsSettingsSuccess,
  SaveComplaintSettingsSuccess,
  SaveDispatchSettingsSuccess,
  SaveDocumentationSettingsSuccess,
  SaveEmailSettingsSuccess,
  SaveExportPathSettingsSuccess,
  SaveFilterSettingsSuccess,
  SaveGoodsInSettingsSuccess,
  SaveLabourSettingsSuccess,
  SaveLogoutSettingsSuccess,
  SaveNonConformancesSettingsSuccess,
  SavePortableDeviceSyncSuccess,
  SavePrintingSettingsSuccess,
  SaveProductStockAndFilterSuccess,
  SavePurchaseOrderDeletionReasonSuccess,
  SavePurchaseOrderSettingsSuccess,
  SaveRecipesSettingsSuccess,
  SaveReportSettingsSuccess,
  SaveSalesOrderSettingsSuccess,
  SaveStockCheckSettingsSuccess,
  SaveSuppliersSettingsSuccess,
  SaveTabletSettingsSuccess,
  SaveTrainingSettingsSuccess,
  SaveVisitorSettingsSuccess,
  SaveWorkRequestNumberSuccess,
} from "./settingsAction";

export interface ISettingsState {
  auditSettings?: any;
  checkSettings?: any;
  dispatchSettings?: any;
  documentationSettings?: any;
  emailSettings?: any;
  exportPathSettings?: any;
  filterSettings?: any;
  labourSettings?: any;
  logoutSettings?: any;
  portableDeviceSync?: any;
  printingSettings?: any;
  productStockAndFilter?: any;
  suppliersSettings?: any;
  recipesSettings?: any;
  stockCheckSettings?: any;
  trainingSettings?: any;
  workRequestNumberSettings?: any;
  goodsInSettings?: any;
  companyDetails?: any;
  salesOrderSettings?: any;
  tabletSettings?: any;
  visitorSettings?: any;
  ncSettings?: any;
  reportSettings?: any;
  complaintSettings?: any;
  purchaseOrderDeleteReasons?: any[];
  purchaseOrderSettings?: any;
  isLoading?: boolean;
}
const initialState: ISettingsState = {};

export const settingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LoadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(GetChecksettingsSuccess, (state, action) => {
      state = {
        ...state,
        checkSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveChecksettingsSuccess, (state, action) => {
      state = {
        ...state,
        checkSettings: action.payload,
      };
      return state;
    })
    .addCase(GetAuditsettingsSuccess, (state, action) => {
      state = {
        ...state,
        auditSettings: action.payload?.data,
      };
      return state;
    })
    .addCase(SaveAuditsettingsSuccess, (state, action) => {
      state = {
        ...state,
        auditSettings: action.payload,
      };
      return state;
    })
    .addCase(GetDispatchSettingsSuccess, (state, action) => {
      state = {
        ...state,
        dispatchSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveDispatchSettingsSuccess, (state, action) => {
      state = {
        ...state,
        dispatchSettings: action.payload,
      };
      return state;
    })
    .addCase(GetDocumentationSettingsSuccess, (state, action) => {
      state = {
        ...state,
        documentationSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveDocumentationSettingsSuccess, (state, action) => {
      state = {
        ...state,
        documentationSettings: action.payload,
      };
      return state;
    })
    .addCase(GetEmailSettingsSuccess, (state, action) => {
      state = {
        ...state,
        emailSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveEmailSettingsSuccess, (state, action) => {
      state = {
        ...state,
        emailSettings: action.payload,
      };
      return state;
    })
    .addCase(GetExportPathSettingsSuccess, (state, action) => {
      state = {
        ...state,
        exportPathSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveExportPathSettingsSuccess, (state, action) => {
      state = {
        ...state,
        exportPathSettings: action.payload,
      };
      return state;
    })
    .addCase(GetFilterSettingsSuccess, (state, action) => {
      state = {
        ...state,
        filterSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveFilterSettingsSuccess, (state, action) => {
      state = {
        ...state,
        filterSettings: action.payload,
      };
      return state;
    })
    .addCase(GetLabourSettingsSuccess, (state, action) => {
      state = {
        ...state,
        labourSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveLabourSettingsSuccess, (state, action) => {
      state = {
        ...state,
        labourSettings: action.payload,
      };
      return state;
    })
    .addCase(GetLogoutSettingsSuccess, (state, action) => {
      state = {
        ...state,
        logoutSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveLogoutSettingsSuccess, (state, action) => {
      state = {
        ...state,
        logoutSettings: action.payload,
      };
      return state;
    })
    .addCase(GetPortableDeviceSyncSuccess, (state, action) => {
      state = {
        ...state,
        portableDeviceSync: action.payload,
      };
      return state;
    })
    .addCase(SavePortableDeviceSyncSuccess, (state, action) => {
      state = {
        ...state,
        portableDeviceSync: action.payload,
      };
      return state;
    })
    .addCase(GetPrintingSettingsSuccess, (state, action) => {
      state = {
        ...state,
        printingSettings: action.payload,
      };
      return state;
    })
    .addCase(SavePrintingSettingsSuccess, (state, action) => {
      state = {
        ...state,
        printingSettings: action.payload,
      };
      return state;
    })
    .addCase(GetProductStockAndFilterSuccess, (state, action) => {
      state = {
        ...state,
        productStockAndFilter: action.payload,
      };
      return state;
    })
    .addCase(SaveProductStockAndFilterSuccess, (state, action) => {
      state = {
        ...state,
        productStockAndFilter: action.payload,
      };
      return state;
    })
    .addCase(GetRecipesSettingsSuccess, (state, action) => {
      state = {
        ...state,
        recipesSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveRecipesSettingsSuccess, (state, action) => {
      state = {
        ...state,
        recipesSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveSuppliersSettingsSuccess, (state, action) => {
      state = {
        ...state,
        suppliersSettings: action.payload,
      };
      return state;
    })

    .addCase(GetSupplierSettingsSuccess, (state, action) => {
      state = {
        ...state,
        suppliersSettings: action.payload,
      };
      return state;
    })
    .addCase(GetStockCheckSettingsSuccess, (state, action) => {
      state = {
        ...state,
        stockCheckSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveStockCheckSettingsSuccess, (state, action) => {
      state = {
        ...state,
        stockCheckSettings: action.payload,
      };
      return state;
    })
    .addCase(GetTrainingSettingsSuccess, (state, action) => {
      state = {
        ...state,
        trainingSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveTrainingSettingsSuccess, (state, action) => {
      state = {
        ...state,
        trainingSettings: action.payload,
      };
      return state;
    })
    .addCase(GetWorkRequestNumberSuccess, (state, action) => {
      state = {
        ...state,
        workRequestNumberSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveWorkRequestNumberSuccess, (state, action) => {
      state = {
        ...state,
        workRequestNumberSettings: action.payload,
      };
      return state;
    })
    .addCase(GetGoodsInSettingsSuccess, (state, action) => {
      state = {
        ...state,
        goodsInSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveGoodsInSettingsSuccess, (state, action) => {
      state = {
        ...state,
        goodsInSettings: action.payload,
      };
      return state;
    })
    .addCase(GetCompanyDetailsSettingsSuccess, (state, action) => {
      state = {
        ...state,
        companyDetails: action.payload,
      };
      return state;
    })
    .addCase(SaveCompanyDetailsSettingsSuccess, (state, action) => {
      state = {
        ...state,
        companyDetails: action.payload,
      };
      return state;
    })
    .addCase(GetSalesOrderSettingsSuccess, (state, action) => {
      state = {
        ...state,
        salesOrderSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveSalesOrderSettingsSuccess, (state, action) => {
      state = {
        ...state,
        salesOrderSettings: action.payload,
      };
      return state;
    })
    .addCase(GetTabletSettingsSuccess, (state, action) => {
      state = {
        ...state,
        tabletSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveTabletSettingsSuccess, (state, action) => {
      state = {
        ...state,
        tabletSettings: action.payload,
      };
      return state;
    })
    .addCase(GetVisitorSettingsSuccess, (state, action) => {
      state = {
        ...state,
        visitorSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveVisitorSettingsSuccess, (state, action) => {
      state = {
        ...state,
        visitorSettings: action.payload,
      };
      return state;
    })
    .addCase(GetNonConformancesSettingsSuccess, (state, action) => {
      state = {
        ...state,
        ncSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveNonConformancesSettingsSuccess, (state, action) => {
      state = {
        ...state,
        ncSettings: action.payload,
      };
      return state;
    })
    .addCase(GetReportSettingsSuccess, (state, action) => {
      state = {
        ...state,
        reportSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveReportSettingsSuccess, (state, action) => {
      state = {
        ...state,
        reportSettings: action.payload,
      };
      return state;
    })
    .addCase(GetComplaintSettingsSuccess, (state, action) => {
      state = {
        ...state,
        complaintSettings: action.payload,
      };
      return state;
    })
    .addCase(SaveComplaintSettingsSuccess, (state, action) => {
      state = {
        ...state,
        complaintSettings: action.payload,
      };
      return state;
    })
    .addCase(GetPurchaseOrderDeletionReasonSuccess, (state, action) => {
      state = {
        ...state,
        purchaseOrderDeleteReasons: action.payload,
      };
      return state;
    })
    .addCase(SavePurchaseOrderDeletionReasonSuccess, (state, action) => {
      state = {
        ...state,
        purchaseOrderDeleteReasons: state.purchaseOrderDeleteReasons.map(
          (reason) =>
            reason?.id === action.payload?.id ? action.payload : reason
        ),
      };
      return state;
    })
    .addCase(AddPurchaseOrderDeletionReasonSuccess, (state, action) => {
      state = {
        ...state,
        purchaseOrderDeleteReasons: [
          ...state.purchaseOrderDeleteReasons,
          action.payload,
        ],
      };
      return state;
    })
    .addCase(GetPurchaseOrderSettingsSuccess, (state, action) => {
      state = {
        ...state,
        purchaseOrderSettings: action.payload,
      };
      return state;
    })
    .addCase(SavePurchaseOrderSettingsSuccess, (state, action) => {
      state = {
        ...state,
        purchaseOrderSettings: action.payload,
      };
      return state;
    });
});
