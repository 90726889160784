import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import { createAbstractBuilder } from "typescript";
import ErrorLogDataAccess from "src/dataAccess/errorLog/ErrorLogDataAccess";
export const getErrorLogSuccess = createAction<any>(
  "Get Error Log List Success"
);
export const loadingList = createAction<boolean>("[EmailHistroy] Loading list");
export const GetErrorLogList = createAsyncThunk<any, IListType>(
  "[EmailHistory] Get Email History List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));
    const result = await ErrorLogDataAccess.getList(parameter).then((res) => {
      console.log(res.data);
      thunkAPI.dispatch(getErrorLogSuccess(res));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
    return result;
  }
);
