import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class customerDataAccess {
  customerList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "company/get/CustomerAll",
      parameters
    );
  };
  // getIncidentInfo = async (params: { id: number | bigint }) => {
  //   var result = await commonDataAccess.getData(
  //     "incident/get/incidentInfo/" + params,
  //     {}
  //   );
  //   return result;
  // }
  // getIncidentIssuesDropdowns = () => {
  //   return commonDataAccess.getData("incident/get/incidentIssuesDropdown");
  // }
  // getIncidentDropdowns = () => {
  //   return commonDataAccess.getData("incident/get/incidentsDropdown");
  // }
  // saveIncidentInfo = async (data: any) => {
  //   var result = await commonDataAccess.postData(
  //     "incident/save/incidentInfo",
  //     data
  //   );
  //   return result;
  // };
  deleteCustomerInfo = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("company/delete/" + params);
    return result;
  };
}

export default new customerDataAccess();
