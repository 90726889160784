import { createReducer } from "@reduxjs/toolkit";
import {
  getUOMAsync,
  loadingList,
  updateUOMNameState,
} from "./configurableTypeActions";

export interface IConfigurableTypesState {
  configurableTypesList: Array<any>;
  UOMName: any;
  dataCount: number;
  isLoading: boolean;
}

const initialState: IConfigurableTypesState = {
  configurableTypesList: [],
  UOMName: "",
  dataCount: 0,
  isLoading: false,
};

export const configurableTypeReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getUOMAsync.fulfilled, (state, action) => {
        state = {
          ...state,
          configurableTypesList: action.payload?.data,
          dataCount: action.payload?.dataCount,
        };
        return state;
      })

      .addCase(updateUOMNameState, (state, action) => {
        state = {
          ...state,
          UOMName: action.payload,
        };
        return state;
      })

      // .addCase(saveRackListSuccess, (state, action) => {
      //   state = {
      //     ...state,
      //     rackList: action.payload,
      //   };
      //   return state;
      // })

      .addCase(loadingList, (state, action) => {
        state = {
          ...state,
          isLoading: action.payload,
        };
        return state;
      });
  }
);
