import { ModalFuncProps } from "antd";
import { globalConfig } from "antd/lib/config-provider";
import { getConfirmLocale } from "antd/lib/modal/locale";
import { ReactNode } from "react";
import ReactDOM from "react-dom";
import destroyFns from "antd/lib/modal/destroyFns";
import { ModalContainer } from "./ModalContainer";

export interface GenericModalProps extends ModalFuncProps {
  component?: ReactNode;
  componentNonNode?: any;
  footer?: ReactNode;
  data?: any;
  reduxContext?: any;
  
}

type ConfigUpdate =
  | GenericModalProps
  | ((prevConfig: GenericModalProps) => GenericModalProps);

const DEFAULT_CONFIG: GenericModalProps = {
  visible: true,
  footer: null,
  title: null,
  maskClosable: true,
};

let defaultRootPrefixCls = "";

function getRootPrefixCls() {
  return defaultRootPrefixCls;
}

export default function GenericModal(config: GenericModalProps) {
  const container = document.createDocumentFragment();

  let updateConfig = { ...DEFAULT_CONFIG, ...config };
  let currentConfig = { ...updateConfig, close } as any;

  function destroy(...args: any[]) {
    ReactDOM.unmountComponentAtNode(container);
    const triggerCancel = args.some((param) => param && param.triggerCancel);
    if (config.onCancel && triggerCancel) {
      config.onCancel(...args);
    }
    for (let i = 0; i < destroyFns.length; i++) {
      const fn = destroyFns[i];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (fn === close) {
        destroyFns.splice(i, 1);
        break;
      }
    }
  }

  function render({ prefixCls: customizePrefixCls, ...props }: any) {
    setTimeout(() => {
      const runtimeLocale = getConfirmLocale();
      const { getPrefixCls, getIconPrefixCls } = globalConfig();
      // because Modal.config  set rootPrefixCls, which is different from other components
      const rootPrefixCls = getPrefixCls(undefined, getRootPrefixCls());
      const prefixCls = customizePrefixCls || `${rootPrefixCls}-modal`;
      const iconPrefixCls = getIconPrefixCls();

      ReactDOM.render(
        <ModalContainer
          {...props}
          prefixCls={prefixCls}
          rootPrefixCls={rootPrefixCls}
          iconPrefixCls={iconPrefixCls}
        />,
        container
      );
    });
  }

  function close(...args: any[]) {
    currentConfig = {
      ...currentConfig,
      visible: false,
      afterClose: () => {
        if (typeof config.afterClose === "function") {
          config.afterClose();
        }
        destroy.apply(this, args);
      },
    };
    render(currentConfig);
  }

  function update(configUpdate: ConfigUpdate) {
    if (typeof configUpdate === "function") {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate,
      };
    }
    render(currentConfig);
  }
  render(currentConfig);
  destroyFns.push(close);

  return {
    destroy: close,
    update,
  };
}

export function onOpenModal(props: GenericModalProps): GenericModalProps {
  let config = { ...props };
  config.component = config.componentNonNode ? (
    <config.componentNonNode />
  ) : (
    props.component
  );
  return {
    okCancel: true,
    ...config,
  };
}
