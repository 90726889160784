import commonDataAccess from "../commonDataAccess";
import { IFilter } from "src/@redux/listRedux";
class EmailHistoryDataAccess{
    getList = (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
      }) => {
        let _filters = {};
        params.filters.forEach((item) => {
          if (item.value && item.value.length > 0) {
            _filters["filter." + item.propertyName] = item.value + "-" + item.type;
          }
        });
        let parameters = {
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          sortPreference: params.sortData,
          ..._filters,
        };
    
        return commonDataAccess.getData("EmailHistory/getall", parameters);
        
        
    };
}
export default new EmailHistoryDataAccess();