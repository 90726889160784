import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class recipeProcessDataAccess {
  getRecipenProcessInfoById = async (id: number | bigint) => {
    var result = await commonDataAccess.getData(
      "RecipeProcess/get/recipeprocess",
      { id: id }
    );
    return result;
  };

  saveRecipeProcessInfo = async (data: any) => {
    var result = await commonDataAccess.postData(
      "RecipeProcess/save/recipeprocess",
      data
    );
    return result;
  };

  getRecipenProcessInfo = async (id: number | bigint) => {
    var result = await commonDataAccess.getData(
      "RecipeProcess/create-edit/recipeprocess",
      { procedureId: id }
    );
    return result;
  };

  getMandatorySteps = async (id: number | bigint) => {
    var result = await commonDataAccess.getData(
      "RecipeProcess/get/mandatorystepsdetails",
      { id: id }
    );
    return result;
  };

  completeOrder = async (id: any) => {
    var result = await commonDataAccess.postData(
      "RecipeProcess/complete-order",
      null,
      { id: id }
    );
    return result;
  };
  RecipeProcessList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("RecipeProcess/getall", parameters);
  };

  deleteRecipeProcessInfo = async (ids: string) => {
    var result = await commonDataAccess.deleteData("RecipeProcess/delete/", {
      ids: ids,
    });
    return result;
  };
}

export default new recipeProcessDataAccess();
