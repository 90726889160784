import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import customerDataAccess from "src/dataAccess/customer/customerDataAccess";

export const getCustomerListSuccess = createAction<any>(
  "[Customer] Get Customer List Success"
);
export const LoadingData = createAction<boolean>("[Customer] Loading data");
export const loadingList = createAction<boolean>("[Customer] Loading list");

export const getCustomerListAsync = createAsyncThunk<any, IListType>(
  "[Customer] Get customer List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await customerDataAccess
      .customerList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getCustomerListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);

export const saveCustomerListSuccess = createAction<any>(
  "[Customer] Save Customer Success"
);

export const saveIncidentListAsync = createAsyncThunk<
  any,
  { incidentId: any; data: any; incidentList: Array<any> }
>("[Incident] Save Incident", async (parameter, thunkAPI) => {
  const { incidentId, data, incidentList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  var updatedIncidentList;
  if (incidentId == "edit") {
    let copyIncidentList = [...incidentList];
    updatedIncidentList = (copyIncidentList ?? []).map((item, index) => {
      let copyItem = { ...item };

      if (copyItem.id == data.id) {
        copyItem = data;
      }
      return copyItem;
    });
  } else {
    let copyIncidentList = [...incidentList];
    updatedIncidentList = [...copyIncidentList, data];
  }
  thunkAPI.dispatch(saveCustomerListSuccess(updatedIncidentList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});
