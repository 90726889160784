import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import visitorDataAccess from "src/dataAccess/Visitor/visitorDataAccess";
import { IListType } from "../store";
import { createAbstractBuilder } from "typescript";

export const getVisitorListSuccess = createAction<any>(
  "[Visitor] Get Visitor List Success"
);
export const loadingList = createAction<boolean>("[Visitor] Loading list");
export const GetVisitorList = createAsyncThunk<any, IListType>(
  "[Visitor] Get Visitor List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));
    const result = await visitorDataAccess.getList(parameter).then((res) => {
      console.log(res);
      thunkAPI.dispatch(getVisitorListSuccess(res));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
    return result;
  }
);
export const DeleteVisitorSuccess = createAction<number[]>(
  "[Visitor] Delete Visitor success"
);
export const saveVisitorListSuccess = createAction<any>(
  "[Visitor] Save Visitor Success"
);

export const saveVisitorListAsync = createAsyncThunk<
  any,
  { visitorId: any; data: any; visitorList: Array<any> }
>("[Visitor] Save Visitor", async (parameter, thunkAPI) => {
  const { visitorId, data, visitorList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  console.log(visitorId);
  var updatedVisitorList;
  if (visitorId == "edit") {
    let copyVisitorList = [...visitorList];
    updatedVisitorList = (copyVisitorList ?? []).map((item, index) => {
      let copyItem = { ...item };

      if (copyItem.id == data.id) {
        copyItem = data;
      }
      return copyItem;
    });
  } else {
    let copyVisitorList = [...visitorList];
    updatedVisitorList = [...copyVisitorList, data];
  }
  thunkAPI.dispatch(saveVisitorListSuccess(updatedVisitorList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});
