import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import incidentDataAccess from "src/dataAccess/incidents/incidentDataAccess";
import foodItemDataAccess from "src/dataAccess/foodItem/foodItemDataAccess";

export const getFoodItemListSuccess = createAction<any>(
  "[FoodItem] Get FoodIte, List Success"
);
export const loadingList = createAction<boolean>("[FoodItem] Loading list");

export const getFoodItemListAsync = createAsyncThunk<any, IListType>(
  "[FoodItem] Get foodItem List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await foodItemDataAccess.foodItemList(parameter).then((res) => {
      thunkAPI.dispatch(getFoodItemListSuccess(res));
      thunkAPI.dispatch(loadingList(false));
      return res;
    });
    return result;
  }
);
export const saveFoodItemListAsync = createAsyncThunk<
  any,
  { foodItemId: any; data: any; foodItemList: Array<any> }
>("[FoodItem] Save Food Item", async (parameter, thunkAPI) => {
  const { foodItemId, data, foodItemList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  console.log(foodItemId);
  var updatedFoodItemList;
  if (foodItemId == "edit") {
    let copyFoodItemList = [...foodItemList];
    updatedFoodItemList = (copyFoodItemList ?? []).map((item, index) => {
      let copyItem = { ...item };

      if (copyItem.id == data.id) {
        copyItem = data;
      }
      return copyItem;
    });
  } else {
    let copyFoodItemList = [...foodItemList];
    updatedFoodItemList = [...copyFoodItemList, data];
  }
  thunkAPI.dispatch(saveFoodItemListSuccess(updatedFoodItemList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});
export const saveFoodItemListSuccess = createAction<any>(
  "[FoodItem] Save FoodItem Success"
);





