import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class ProductBatchCodeDataAccess {
  quarantineList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData(
      "productBatchCode/getall-quarantine",
      parameters
    );
  };
  getBatchCodeList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData(
      "ProductRackBatch/getall-batchcode",
      parameters
    );
  };
  getAllGRNDropDown = () => {
    return commonDataAccess.getData("ProductBatchCode/get/GRNDropDown", {
      
    });
  };
}
export default new ProductBatchCodeDataAccess();
