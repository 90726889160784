import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import unitConversionDataaccess from "src/dataAccess/unitConversions/unitConversionDataaccess";
import { IListType } from "../store";

export const loadingList = createAction<boolean>(
  "[Configurable Types] Loading list"
);
export const updateUOMNameState = createAction<boolean>(
  "[Configurable Types] update uom name"
);
export const getListAsync = createAsyncThunk<any, IListType>(
  "[Configurable Types] Get Configurable Types List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await unitConversionDataaccess
      .getList(parameter)
      .then((res) => {
        //thunkAPI.dispatch(getRackListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
