import { createReducer } from "@reduxjs/toolkit";
import {
  GetQurantineList,
  getBatchCodeListSuccess,
  getQurantineListSuccess,
} from "./productBatchCodeAction";
import { get } from "http";
export interface IQurantineState {
  qurantineList?: Array<any>;
  batchCodeList?: Array<any>;
  dataCount: number;
  isLoading?: boolean;
}
const initialState: IQurantineState = {
  qurantineList: [],
  batchCodeList: [],
  dataCount: 0,
  isLoading: false,
};
export const ProductBatchCodeReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getQurantineListSuccess, (state, action) => {
        state = {
          ...state,
          qurantineList: action.payload?.data,
          dataCount: action.payload?.dataCount,
        };
        return state;
      })
      .addCase(getBatchCodeListSuccess, (state, action) => {
        state = {
          ...state,
          batchCodeList: action.payload?.data,
          dataCount: action.payload?.dataCount,
        };
        return state;
      });
    // .addCase(getBatchCodeListSuccess, (state, action) => {
    //   state = {
    //     ...state,
    //     batchCodeList: action.payload?.data,
    //     dataCount: action.payload?.dataCount,
    //   };
    //   return state;
    // });
  }
);
