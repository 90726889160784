import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class downTimeReasonDataAccess {
  downTimeReasonList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("downTimeReasons/getall", parameters);
  };
  deleteDownTimeReasons = async (ids: string) => {
    var result = await commonDataAccess.deleteData("downTimeReasons/delete", {
      ids: ids,
    });
    return result;
  };
  getDownTimeReasonInfo = async (downTimeReasonId) => {
    var result = await commonDataAccess.getData(
      `downTimeReasons/get/downTimeReasonInfo`,
      {
        id: downTimeReasonId,
      }
    );
    console.log(result);
    return result;
  };
  saveDownTimeReasonInfo = async (data: any) => {
    var result = await commonDataAccess.postData(
      "downTimeReasons/save/downTimeReasonInfo",
      data
    );
    return result;
  };
}

export default new downTimeReasonDataAccess();
