import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import goodsInProcessDataAccess from "src/dataAccess/goodsInProcess/goodsInProcessDataAccess";

export const getGoodsInProcessListSuccess = createAction<any>(
  "[GoodsInProcess] Get GoodsInProcess List Success"
);
export const loadingList = createAction<boolean>(
  "[GoodsInProcess] Loading list"
);
export const updateHasAnyChange = createAction<boolean>(
  "[GoodsInProcess] Update Has Any Change"
);
export const updateSaveAndCompleteOrder = createAction<boolean>(
  "[GoodsInProcess] Update Save And Complete Order"
);
export const updateSignClearCount = createAction<number>(
  "[GoodsInProcess] Clear Sign"
);
export const resetSignClearCount = createAction<number>(
  "[GoodsInProcess] Reset Count"
);
export const getGoodsInProcessListAsync = createAsyncThunk<any, IListType>(
  "[GoodsInProcess] Get GoodsInProcess List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await goodsInProcessDataAccess
      .GoodsInProcessList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getGoodsInProcessListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);

export const saveGoodsInProcessListSuccess = createAction<any>(
  "[GoodsInProcess] Save GoodsInProcess Success"
);

export const saveGoodsInProcessListAsync = createAsyncThunk<
  any,
  { id: any; data: any; goodsInProcessList: Array<any> }
>("[GoodsInProcess] Save GoodsInProcess", async (parameter, thunkAPI) => {
  const { id, data, goodsInProcessList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  var updatedGoodsInProcessList;
  if (id == "edit") {
    let copyGoodsInProcessList = [...goodsInProcessList];
    updatedGoodsInProcessList = (copyGoodsInProcessList ?? []).map(
      (item, index) => {
        let copyItem = { ...item };

        if (copyItem.id == data.id) {
          copyItem = data;
        }
        return copyItem;
      }
    );
  } else {
    let copyGoodsInProcessList = [...goodsInProcessList];
    updatedGoodsInProcessList = [...copyGoodsInProcessList, data];
  }
  thunkAPI.dispatch(saveGoodsInProcessListSuccess(updatedGoodsInProcessList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});
