import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class goodsInProcessDataAccess {
  saveImageStep(payload: { goodsInProcessDetailId: any; image: any }) {
    return commonDataAccess.postData("goodsInprocess/save/imageStep", payload);
  }
  saveSignatureStep(payload: { goodsInProcessDetailId: any; signature: any }) {
    return commonDataAccess.postData(
      "goodsInprocess/save/signatureStep",
      payload
    );
  }
  // saveTrainerSignature = (payload: any) => {

  // };
  GoodsInProcessList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("goodsInprocess/getall", parameters);
  };
  getGoodsInProcessInfo = async (id: number | bigint) => {
    var result = await commonDataAccess.getData(
      "goodsInprocess/get/goodsInprocessInfo",
      { id: id }
    );
    return result;
  };
  getMandatorySteps = async (id: number | bigint) => {
    var result = await commonDataAccess.getData(
      "goodsInprocess/get/mandatorystepsdetails",
      { id: id }
    );
    return result;
  };
  createGoodsInProcessFromProcedure = async (
    procedureId: any,
    supplierId: any
  ) => {
    var result = await commonDataAccess.postData(
      "goodsInprocess/create/GoodsInProcessFromProcedure",
      null,
      { procedureId: procedureId, supplierId: supplierId }
    );
    return result;
  };
  saveGoodsInProcessInfo = async (data: any) => {
    var result = await commonDataAccess.postData(
      "goodsInprocess/save/GoodsInProcess",
      data
    );
    return result;
  };
  completeOrder = async (id: any) => {
    var result = await commonDataAccess.postData(
      "goodsInprocess/complete-order",
      null,
      { id: id }
    );
    return result;
  };

  deleteGoodsInProcessInfo = async (ids: string) => {
    var result = await commonDataAccess.deleteData("goodsInprocess/delete/", {
      ids: ids,
    });
    return result;
  };
}

export default new goodsInProcessDataAccess();
