import { createReducer } from "@reduxjs/toolkit";
import {
  DeleteVisitorSuccess,
  GetVisitorList,
  getVisitorListSuccess,
  loadingList,
  saveVisitorListSuccess,
} from "./visitorAction";

export interface IVisitorState {
  visitorList?: Array<any>;
  dataCount: number;
  isLoading?: boolean;
}
const initialState: IVisitorState = {
  visitorList: [],
  dataCount: 0,
  isLoading: false,
};
export const VisitorReducer = createReducer(initialState, (builder) => {
  builder
    
    .addCase(getVisitorListSuccess, (state, action) => {
      state = {
        ...state,
        visitorList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(DeleteVisitorSuccess, (state, action) => {
      let filteredData = state.visitorList.filter(
        (item) => !action.payload.includes(item.id)
      );
      state = {
        ...state,
        visitorList: filteredData,
      };
      return state;
    })
    .addCase(GetVisitorList.fulfilled, (state, action) => {
      state = {
        ...state,
        visitorList: action.payload?.data,
        dataCount: action.payload?.dataCount,
        isLoading: false,
      };
      return state;
    })
    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(saveVisitorListSuccess, (state, action) => {
      state = {
        ...state,
        visitorList: action.payload,
      };
      return state;
    });
});
