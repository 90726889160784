import { IFilter } from "src/@redux/listRedux";
import { formatFilterForQueryParam } from "src/common/service";
import commonDataAccess from "../commonDataAccess";

class ComplaintDataAccess {
  getList = (params: {
    pageNo;
    pageSize;
    sortData;
    complaintStatus;
    filters: Array<IFilter>;
  }) => {
    let _filters = formatFilterForQueryParam(params.filters);
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      complaintStatus: params.complaintStatus,
      ..._filters,
    };
    console.log(params.complaintStatus);
    return commonDataAccess.getData("complaint/getall", parameters);
  };
  getGeneral = (id: any) => {
    return commonDataAccess.getData(`complaint/get/general/${id}`);
  };
  saveComplaint = (payload: any) => {
    return commonDataAccess.postData("complaint/save/general", payload);
  };
  getAuditTrail = (complaintId: any) => {
    return commonDataAccess.getData(`complaint/get/auditTrail/${complaintId}`);
  };
  closeDownComplaint = (complaintId: any) => {
    return commonDataAccess.getData(
      `complaint/closeDownComplaint/${complaintId}`
    );
  };

  deleteComplaintById = (complaintIds: string, deletionReason: string) => {
    return commonDataAccess.deleteData(`complaint/delete`, {
      complaintIds,
      deletionReason,
    });
  };
  getComplaintContactEmail = () => {
    return commonDataAccess.getData("complaint/get/complaintContactEmail");
  };
  emailComplaint = (payload: any) => {
    return commonDataAccess.postData("complaint/emailComplaint", payload);
  };
  copyComplaint = (complaintId: any) => {
    return commonDataAccess.getData(`complaint/copy/${complaintId}`);
  };
  printComplaintReport = (complaintId: any) => {
    return commonDataAccess.downloadFile(
      `complaint/printComplaintReport/${complaintId}`
    );
  };
  getComplaintsCount = () => {
    return commonDataAccess.getData("complaint/get/complaintsCount");
  };
  getComplaintDropdown = () => {
    return commonDataAccess.getData("complaint/get/dropdown");
  };
}

export default new ComplaintDataAccess();
