import { createReducer } from "@reduxjs/toolkit";
import { getFoodItemListSuccess,loadingList, saveFoodItemListSuccess} from "./FoodItemAction";

export interface IFoodItemState {
  foodItemList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: IFoodItemState = {
  foodItemList: [],
  dataCount: 0,
  isLoading: false,
};

export const FoodItemReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFoodItemListSuccess, (state, action) => {
      state = {
        ...state,
        foodItemList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(saveFoodItemListSuccess, (state, action) => {
      state = {
        ...state,
        foodItemList: action.payload,
      };
      return state;
    })
});
