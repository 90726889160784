import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";
class foodItemDataAccess {
foodItemList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("foodItem/getall", parameters);
  };
  getFoodItemInfo = async (foodItemId) => {
    var result = await commonDataAccess.getData(`foodItem/get/foodItemInfo`,{id : foodItemId});
    console.log(result);
    return result;
  };
  saveFoodItemInfo = async (data: any) => {
    var result = await commonDataAccess.postData("foodItem/save/foodItemInfo", data);
    return result;
  };
  getFoodItemDropDown = () => {
    return commonDataAccess.getData("foodItem/get/FoodItemDropDown");
  };
  deleteFoodItemInfo = async ( ids: string ) => {
    var result = await commonDataAccess.deleteData("foodItem/delete", {ids:ids});
    return result;
  };
}
  
export default new foodItemDataAccess();