import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";
import { formatFilterForQueryParam } from "src/common/service";

export class UnitConversionDataAccess {
  saveUnitConversion = (payload: any) => {
    return commonDataAccess.postData(`UnitConversions/save`, payload);
  };
  deleteUnitConveriosn = (ids: any) => {
    return commonDataAccess.deleteData("UnitConversions/delete", {
      ids: ids,
    });
  };
  getList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    // console.log(params.complaintStatus);
    return commonDataAccess.getData("UnitConversions/getall", parameters);
  };
  getById = (uomId) => {
    return commonDataAccess.getData("UnitConversions/getById", {
      id: uomId,
    });
  };
}

export default new UnitConversionDataAccess();
