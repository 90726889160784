import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import productbatchcodedataaccess from "src/dataAccess/products/productbatchcodedataaccess";
export const getQurantineListSuccess = createAction<any>(
  "[Qurantine] Get Qurantine List Success"
);
export const getBatchCodeListSuccess = createAction<any>(
  "[BatchCode] Get Batch Code List Success"
);
export const loadingList = createAction<boolean>("[EmailHistroy] Loading list");
export const GetQurantineList = createAsyncThunk<any, IListType>(
  "[Qurantine] Get Qurantine List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));
    const result = await productbatchcodedataaccess
      .quarantineList(parameter)
      .then((res) => {
        console.log(res.data);
        thunkAPI.dispatch(getQurantineListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
export const GetBatchCodeList = createAsyncThunk<any, IListType>(
  "[BatchCode] Get Batch Code List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));
    const result = await productbatchcodedataaccess
      .getBatchCodeList(parameter)
      .then((res) => {
        console.log(res.data);
        thunkAPI.dispatch(getBatchCodeListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
