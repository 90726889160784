import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import configurableTypeDataaccess from "src/dataAccess/configurableTypes/configurableType.dataaccess";

export const loadingList = createAction<boolean>(
  "[Configurable Types] Loading list"
);
export const updateUOMNameState = createAction<boolean>(
  "[Configurable Types] update uom name"
);
export const getUOMAsync = createAsyncThunk<any, IListType>(
  "[Configurable Types] Get Configurable Types List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await configurableTypeDataaccess
      .getUOMList(parameter)
      .then((res) => {
        //thunkAPI.dispatch(getRackListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      });
    return result;
  }
);
