import { createReducer } from "@reduxjs/toolkit";
import {
  getCustomerListSuccess,
  LoadingData,
  loadingList,
  saveCustomerListSuccess,
} from "./customerActions";

export interface ICustomerState {
  customerList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: ICustomerState = {
  customerList: [],
  dataCount: 0,
  isLoading: false,
};

export const customerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LoadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(getCustomerListSuccess, (state, action) => {
      state = {
        ...state,
        customerList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    .addCase(saveCustomerListSuccess, (state, action) => {
      state = {
        ...state,
        customerList: action.payload,
      };
      return state;
    })

    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
