import { createReducer } from "@reduxjs/toolkit";
import {
  getGoodsInProcessListSuccess,
  loadingList,
  resetSignClearCount,
  saveGoodsInProcessListSuccess,
  updateHasAnyChange,
  updateSaveAndCompleteOrder,
  updateSignClearCount,
} from "./GoodsInProcessActions";

export interface IGoodsInProcessState {
  goodsInprocessList: Array<any>;
  dataCount: number;
  isLoading: boolean;
  triggerSignClearCount: number;
  hasAnyChange: boolean;
  saveAndCompleteOrder: boolean;
}

const initialState: IGoodsInProcessState = {
  goodsInprocessList: [],
  dataCount: 0,
  isLoading: false,
  triggerSignClearCount: 0,
  hasAnyChange: false,
  saveAndCompleteOrder: false,
};

export const goodsInProcessReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getGoodsInProcessListSuccess, (state, action) => {
      state = {
        ...state,
        goodsInprocessList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    .addCase(saveGoodsInProcessListSuccess, (state, action) => {
      state = {
        ...state,
        goodsInprocessList: action.payload,
      };
      return state;
    })

    .addCase(updateSignClearCount, (state, action) => {
      console.log(action.payload);
      state = {
        ...state,
        triggerSignClearCount: state.triggerSignClearCount + 1,
      };
      return state;
    })
    .addCase(resetSignClearCount, (state, action) => {
      console.log(action.payload);
      state = {
        ...state,
        triggerSignClearCount: 0,
      };
      return state;
    })
    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(updateHasAnyChange, (state, action) => {
      state = {
        ...state,
        hasAnyChange: action.payload,
      };
      return state;
    })
    .addCase(updateSaveAndCompleteOrder, (state, action) => {
      state = {
        ...state,
        saveAndCompleteOrder: action.payload,
      };
      return state;
    });
});
