import { createReducer } from "@reduxjs/toolkit";
import {
  getRecipeProcessListSuccess,
  loadingList,
} from "./RecipeProcessActions";

export interface IRecipeProcessState {
  recipeProcessList: Array<any>;
  dataCount: number;
  isLoading: boolean;
  //triggerSignClearCount: number;
}

const initialState: IRecipeProcessState = {
  recipeProcessList: [],
  dataCount: 0,
  isLoading: false,
  //triggerSignClearCount: 0,
};

export const recipeProcessReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRecipeProcessListSuccess, (state, action) => {
      state = {
        ...state,
        recipeProcessList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    // .addCase(saveGoodsInProcessListSuccess, (state, action) => {
    //   state = {
    //     ...state,
    //     goodsInprocessList: action.payload,
    //   };
    //   return state;
    // })

    // .addCase(updateSignClearCount, (state, action) => {
    //   console.log(action.payload);
    //   state = {
    //     ...state,
    //     triggerSignClearCount: state.triggerSignClearCount + 1,
    //   };
    //   return state;
    // })
    // .addCase(resetSignClearCount, (state, action) => {
    //   console.log(action.payload);
    //   state = {
    //     ...state,
    //     triggerSignClearCount: 0,
    //   };
    //   return state;
    // })
    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
