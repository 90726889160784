import { createReducer } from "@reduxjs/toolkit";
import {
  getDownTimeReasonListSuccess,
  loadingList,
  saveDownTimeReasonListSuccess,
} from "./downTimeReasonAction";
import { get } from "http";

export interface IDownTimeReasonState {
  downTimeReasonList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: IDownTimeReasonState = {
  downTimeReasonList: [],
  dataCount: 0,
  isLoading: false,
};

export const downTimeReasonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getDownTimeReasonListSuccess, (state, action) => {
      state = {
        ...state,
        downTimeReasonList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(saveDownTimeReasonListSuccess, (state, action) => {
      state = {
        ...state,
        downTimeReasonList: action.payload,
      };
      return state;
    });
});
