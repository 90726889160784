import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class purchaseOrderDataAccess {
  sendEmail(data: any, organizationId: any) {
    return commonDataAccess.postData(
      `PurchaseOrderReport/send/poemail/${organizationId}`,
      data,
      null,
      "Legacy"
    );
  }
  savePODetails(data: any, poId: any) {
    return commonDataAccess.postData("PurchaseOrder/save/poDetails", data, {
      poId: poId,
    });
  }
  GetProductDetails(poId: number) {
    return commonDataAccess.getData("PurchaseOrder/get/poDetails", {
      id: poId,
    });
  }
  deletePoDetails(id: any) {
    return commonDataAccess.deleteData("PurchaseOrder/delete/podetail", {
      poDetailId: id,
    });
  }
  saveGeneralInfo(data: any) {
    return commonDataAccess.postData("PurchaseOrder/save/basicinfo", data);
  }
  getGeneralInfo(poId: any) {
    return commonDataAccess.getData("PurchaseOrder/get/basicinfo", {
      id: poId,
    });
  }
  getDeliveryLocation() {
    return commonDataAccess.getData(
      "DeliverLocation/get/deliver-locations-dropdown"
    );
  }

  getAll = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
    //potype;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    // _filters["filter.Status"] = params.potype;
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("PurchaseOrder/getall", parameters);
  };
  // deletePurchaseOrder = (id: number) => {
  //   return commonDataAccess.deleteData(`​PurchaseOrder​/delete/${id}`)
  // };
  deletePurchaseOrderById = (
    purchaseOrderIds: string,
    deletionReasonId: any
  ) => {
    return commonDataAccess.deleteData(`PurchaseOrder/delete`, {
      purchaseOrderIds,
      deletionReasonId,
    });
  };

  getPOdetailBySupplierId(supplierId: any) {
    return commonDataAccess.getData("PurchaseOrder/get/supplierinfo", {
      id: supplierId,
    });
  }
  copyPurchaseOrders(ids: string) {
    return commonDataAccess.getData("PurchaseOrder/copy", { ids: ids });
  }
  GetPOMail(Ids: String) {
    return commonDataAccess.getData("PurchaseOrder/get/emaildata", {
      ids: Ids,
    });
  }
  getPurchaseOrdersCount = () => {
    return commonDataAccess.getData("PurchaseOrder/get/PurchaseOrderCount");
  };
  getAllPurchaseOrderNoDropDown = () => {
    return commonDataAccess.getData(
      "PurchaseOrder/get/PurchaseOrderNoDropDown",
      {}
    );
  };
}

export default new purchaseOrderDataAccess();
