import { createReducer } from "@reduxjs/toolkit";
import {
  ExceptionConfig,
  ExceptionProcessConfig,
  ExceptionSetting,
  SCExceptionProcessConfig,
} from "src/models/exception-process.model";
import { ExceptionCount, ExceptionCountMap } from "src/models/exception.model";
import {
  getCautionList,
  GetExceptionCautionCountAsync,
  getNCList,
  getSNCList,
  NcStatus,
  SetCautionCount,
  SetExceptionConfig,
  SetExceptionName,
  SetExceptionProcessConfig,
  SetExceptionSettings,
  SetExceptionTypeProcedure,
  SetNCExceptionCount,
  updateCopyCommment,
  UpdateCurrentNCStatus,
  UpdateExceptionProcessConfig,
  UpdateSCExceptionProcessConfig,
} from "./exception.action";

export interface IException {
  list: any[];
  sncList: any[];
  dataCount: number;
  exceptionStatus: NcStatus;
  loading: boolean;
  exceptionProcessConfig: ExceptionProcessConfig;
  scExceptionProcessConfig: SCExceptionProcessConfig;
  exceptionSettings: ExceptionSetting;
  exceptionConfig: ExceptionConfig;
  exceptionCounts: ExceptionCountMap;
  exceptionName: string;
  cautionList: any[];
  exceptionTypeProcedure: number;
  copyComment: boolean;
}

const initialState: Partial<IException> = {
  list: [],
  dataCount: 0,
  exceptionTypeProcedure: 0,
  copyComment: false,
};

export const exceptionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getNCList.pending, (state, action) => {
      state = {
        ...state,
        loading: true,
      };
      return state;
    })
    .addCase(getNCList.fulfilled, (state, action) => {
      state = {
        ...state,
        list: action.payload?.data,
        dataCount: action.payload?.dataCount,
        loading: false,
      };
      return state;
    })
    .addCase(getSNCList.pending, (state, action) => {
      state = {
        ...state,
        loading: true,
      };
      return state;
    })
    .addCase(getSNCList.fulfilled, (state, action) => {
      state = {
        ...state,
        sncList: action.payload?.data,
        dataCount: action.payload?.dataCount,
        loading: false,
      };
      return state;
    })
    .addCase(getCautionList.pending, (state, action) => {
      state = {
        ...state,
        loading: true,
      };
      return state;
    })
    .addCase(getCautionList.fulfilled, (state, action) => {
      state = {
        ...state,
        cautionList: action.payload?.data,
        dataCount: action.payload?.dataCount,
        loading: false,
      };
      return state;
    })
    .addCase(UpdateCurrentNCStatus, (state, action) => {
      state = {
        ...state,
        exceptionStatus: {
          ...state.exceptionStatus,
          ...action.payload,
        },
      };
      return state;
    })
    .addCase(SetExceptionProcessConfig, (state, action) => {
      state = {
        ...state,
        exceptionProcessConfig: action.payload,
      };
      return state;
    })
    .addCase(UpdateExceptionProcessConfig, (state, action) => {
      state = {
        ...state,
        exceptionProcessConfig: {
          ...state.exceptionProcessConfig,
          ...(action.payload as ExceptionProcessConfig),
        },
      };

      return state;
    })
    .addCase(UpdateSCExceptionProcessConfig, (state, action) => {
      state = {
        ...state,
        scExceptionProcessConfig: {
          ...state.scExceptionProcessConfig,
          ...(action.payload as SCExceptionProcessConfig),
        },
      };

      return state;
    })
    .addCase(updateCopyCommment, (state, action) => {
      state = {
        ...state,
        copyComment: action.payload,
      };
      return state;
    })

    .addCase(SetExceptionSettings, (state, action) => {
      state = {
        ...state,
        exceptionSettings: {
          ...state?.exceptionSettings,
          ...(action.payload as ExceptionSetting),
        },
      };
      return state;
    })
    .addCase(SetExceptionConfig, (state, action) => {
      state = {
        ...state,
        exceptionConfig: {
          ...state?.exceptionConfig,
          ...action.payload,
        },
      };
      return state;
    })
    .addCase(GetExceptionCautionCountAsync.fulfilled, (state, action) => {
      let data = {
        ...state.exceptionCounts,
        ...action.payload,
      };
      state = {
        ...state,
        exceptionCounts: data,
      };
      return state;
    })
    .addCase(SetExceptionName, (state, action) => ({
      ...state,
      exceptionName: action?.payload,
    }))
    .addCase(SetExceptionTypeProcedure, (state, action) => ({
      ...state,
      exceptionTypeProcedure: action?.payload,
    }))
    .addCase(SetNCExceptionCount, (state, action) => {
      let data = { ...state.exceptionCounts?.exception };
      let update = {
        ...data,
        ...(action.payload as ExceptionCount),
      };

      state = {
        ...state,
        exceptionCounts: {
          ...state.exceptionCounts,
          exception: update,
        },
      };
      return state;
    })

    .addCase(SetCautionCount, (state, action) => {
      let data = { ...state.exceptionCounts?.caution };
      let update = {
        ...data,
        ...(action.payload as ExceptionCount),
      };
      state = {
        ...state,
        exceptionCounts: {
          ...state.exceptionCounts,
          caution: update,
        },
      };
      return state;
    });
});
