import { createReducer } from "@reduxjs/toolkit";
import {
  getCAProfileByCaIdSuccess,
  getCheckListDropdownSuccess,
  getCorrectiveActionListAsync,
  getNCItemsSuccess,
  getNonConformanceDropdownSuccess,
  getSubNCs,
  // AddSubNc,
  updateNCItemStateSuccess,
  AddSubNC,
  RemoveSubNC,
  getCheckListDropdown,
  getNCItems,
  AddNcItem,
  RemoveNcItem,
  SetSubNC,
  getNonConformanceDropdown,
  updateHasToSendEmail,
  getSiteItems,
  getSiteItemsSuccess,
  loadingList,
  getCheckListSuccess,
  setChecklistDetails,
  updateHasToShowAssignUsers,
} from "./nonConformanceAction";

export interface INonConformanceState {
  nonFirmanceDropdown?: any[];
  checkListDropdown: any[];
  checkList: any[];
  nCList: any[];
  dataCount: number;
  isLoading: boolean;
  caProfiles: any[];
  subNCList: any[];
  nCItemList: any[];
  loadSubNc: boolean;
  loadCaActions: boolean;
  hasToSendEmail: boolean;
  hasToShowAssignUsers: boolean;
  siteId: number;
  checklistDetails: any;
}

export const initialState: Partial<INonConformanceState> = {};

export const nonConformanceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(getCheckListSuccess, (state, action) => {
      state = {
        ...state,
        checkList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(getNonConformanceDropdownSuccess, (state, action) => {
      state = {
        ...state,
        nonFirmanceDropdown: action.payload,
      };
      return state;
    })
    .addCase(getNonConformanceDropdown.fulfilled, (state, action) => {
      state = {
        ...state,
        nonFirmanceDropdown: action.payload,
      };
      return state;
    })
    .addCase(getNCItems.pending, (state, action) => {
      state = {
        ...state,
        loadCaActions: true,
      };
      return state;
    })
    .addCase(getNCItems.fulfilled, (state, action) => {
      let data = (action.payload ?? []).map((item, index) => {
        item.key = index;
        return item;
      });

      state = {
        ...state,
        loadCaActions: false,
        nCItemList: data,
      };
      return state;
    })
    .addCase(getNCItemsSuccess, (state, action) => {
      let data = (action.payload ?? []).map((item, index) => {
        item.key = index;
        return item;
      });
      state = {
        ...state,
        nCItemList: data,
      };
      return state;
    })
    .addCase(AddNcItem, (state, action) => {
      let update = {
        ...action.payload,
        key: (state.nCItemList ?? [])?.length + 1,
        sequencePosition: (state.nCItemList ?? [])?.length + 1,
        isActive: true,
      };
      state = {
        ...state,
        nCItemList: (state.nCItemList ?? []).concat(update),
      };
      return state;
    })
    .addCase(RemoveNcItem, (state, action) => {
      let data = state?.nCItemList.filter(
        (item) => item.key !== action.payload.key
      );
      let manageSequence = data.map((item, index) => {
        let cpyObject = Object.assign({}, item);
        cpyObject.sequencePosition = index + 1;
        return cpyObject;
      });
      state = {
        ...state,
        nCItemList: manageSequence,
      };
      return state;
    })
    .addCase(getCheckListDropdownSuccess, (state, action) => {
      state = {
        ...state,
        checkListDropdown: action.payload,
      };
      return state;
    })
    .addCase(getCorrectiveActionListAsync.pending, (state, action) => {
      state = {
        ...state,
        isLoading: true,
      };
      return state;
    })
    .addCase(getCorrectiveActionListAsync.fulfilled, (state, action) => {
      state = {
        ...state,
        isLoading: false,
        nCList: action?.payload?.data,
        dataCount: action?.payload?.dataCount,
      };
      return state;
    })
    .addCase(getCAProfileByCaIdSuccess, (state, action) => {
      // let data = action.payload?.data
      //   .map((item) => {
      //     let obj = {
      //       value: item.id,
      //       label: item.name,
      //     };
      //     return obj;
      //   })
      //   .filter((e) => e !== undefined);
      state = {
        ...state,
        caProfiles: action?.payload,
      };
      return state;
    })
    .addCase(getSubNCs.pending, (state, action) => {
      state = {
        ...state,
        loadSubNc: true,
      };
      return state;
    })
    .addCase(getSubNCs.fulfilled, (state, action) => {
      let data = (action.payload ?? []).map((item, index) => {
        item.key = index;
        return item;
      });
      state = {
        ...state,
        subNCList: data,
        loadSubNc: false,
      };
      return state;
    })
    .addCase(SetSubNC, (state, action) => {
      let data = (action.payload ?? []).map((item, index) => {
        item.key = index;
        return item;
      });

      state = {
        ...state,
        subNCList: data,
      };
      return state;
    })
    .addCase(AddSubNC, (state, action) => {
      let update = {
        ...action.payload,
        key: (state.subNCList ?? [])?.length + 1,
      };
      console.log(update);
      state = {
        ...state,
        subNCList: (state?.subNCList ?? []).concat(update),
      };
      return state;
    })
    .addCase(RemoveSubNC, (state, action) => {
      let filter = state.subNCList.filter(
        (item) => item.key !== action.payload.key
      );
      state = {
        ...state,
        subNCList: filter,
      };
      return state;
    })

    .addCase(updateNCItemStateSuccess, (state, action) => {
      state = {
        ...state,
        nCItemList: action.payload,
      };
      return state;
    })
    .addCase(updateHasToSendEmail, (state, action) => {
      console.log(action);
      state = {
        ...state,
        hasToSendEmail: action.payload,
      };
      return state;
    })
    .addCase(updateHasToShowAssignUsers, (state, action) => {
      console.log(action);
      state = {
        ...state,
        hasToShowAssignUsers: action.payload,
      };
      return state;
    })
    .addCase(getSiteItems.pending, (state, action) => {
      state = {
        ...state,
        loadCaActions: true,
      };
      return state;
    })
    .addCase(getSiteItems.fulfilled, (state, action) => {
      let data = (action.payload.siteItemList ?? []).map((item, index) => {
        item.key = index;
        return item;
      });

      state = {
        ...state,
        loadCaActions: false,
        nCItemList: data,
        siteId: action.payload.ncId,
      };
      return state;
    })
    .addCase(getSiteItemsSuccess, (state, action) => {
      let data = (action.payload ?? []).map((item, index) => {
        item.key = index;
        return item;
      });
      state = {
        ...state,
        nCItemList: data.sort((a, b) => a.sequencePosition - b.sequencePosition)
      };
      return state;
    })
    .addCase(setChecklistDetails, (state, action) => {
      state = {
        ...state,
        checklistDetails: action.payload,
      };
      return state;
    });
});
