import { createReducer } from "@reduxjs/toolkit";
import { getListAsync, loadingList } from "./UnitConversionAction";

export interface IUnitConversionState {
  unitConversionList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: IUnitConversionState = {
  unitConversionList: [],
  dataCount: 0,
  isLoading: false,
};

export const unitConversionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getListAsync.fulfilled, (state, action) => {
      state = {
        ...state,
        unitConversionList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    // .addCase(updateUOMNameState, (state, action) => {
    //   state = {
    //     ...state,
    //     UOMName: action.payload,
    //   };
    //   return state;
    // })

    // .addCase(saveRackListSuccess, (state, action) => {
    //   state = {
    //     ...state,
    //     rackList: action.payload,
    //   };
    //   return state;
    // })

    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
