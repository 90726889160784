import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class salesOrderDataAccess {
  completeDispatch(ids: any) {
    return commonDataAccess.getData("salesorder/complete-dispatch", {
      ids: ids,
    });
  }
  copyOrders(id: any) {
    return commonDataAccess.getData("salesorder/copy", { id: id });
  }
  sendEmail(data: any, organizationId: any) {
    return commonDataAccess.postData(
      `PurchaseOrderReport/send/soemail/${organizationId}`,
      data,
      null,
      "Legacy"
    );
  }
  GetSOMailData(Id: any) {
    return commonDataAccess.getData("salesorder/get/emaildata", {
      id: Id,
    });
  }
  deleteOrder(id: any) {
    return commonDataAccess.deleteData("salesorder/delete", {
      id: id,
    });
  }
  deleteSoDetails(id: any) {
    return commonDataAccess.deleteData("salesorder/delete/sodetail", {
      soDetailId: id,
    });
  }
  getProductDetailForSalesOrder(productId: any) {
    return commonDataAccess.getData("salesorder/get/productDetails", {
      productId: productId,
    });
  }
  getSOdetailByCustomerId(customerId: any) {
    return commonDataAccess.getData("salesorder/get/customerInfo", {
      id: customerId,
    });
  }
  getSoDetailsById(id: any) {
    return commonDataAccess.getData("salesorder/get/order-details", {
      id: id,
    });
  }
  getPreviousSOdetailByCustomerId(customerId: any, soId: any) {
    return commonDataAccess.getData("salesorder/get/previous-orders", {
      id: soId,
      customerId: customerId,
    });
  }
  saveSalesOrderInfo(data: any) {
    return commonDataAccess.postData("salesorder/save/basicInfo", data);
  }
  getSalesOrderInfo(soId: any) {
    return commonDataAccess
      .getData("salesorder/get/basicInfo/" + soId)
      .then((res) => res);
  }
  salesOrderList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("salesorder/getall", parameters);
  };
}

export default new salesOrderDataAccess();
