import commonDataAccess from "../commonDataAccess";
import { IFilter } from "src/@redux/listRedux";

class VisitorDataAccess{
    getList = (params: {
        pageNo;
        pageSize;
        sortData;
        filters: Array<IFilter>;
      }) => {
        let _filters = {};
        params.filters.forEach((item) => {
          if (item.value && item.value.length > 0) {
            _filters["filter." + item.propertyName] = item.value + "-" + item.type;
          }
        });
        let parameters = {
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          sortPreference: params.sortData,
          ..._filters,
        };
    
        return commonDataAccess.getData("visitor/getall", parameters);
        
        
    };
    deleteVisitor = (visitorIds: string) => {
      return commonDataAccess.deleteData(`Visitor/delete`,{ids:visitorIds});
    };
    getVisitorInfo = async (visitorId) => {
      var result = await commonDataAccess.getData(`Visitor/get/visitorInfo`,{id : visitorId});
      console.log(result);
      return result;
    };
    saveVisitorInfo = async (data: any) => {
      var result = await commonDataAccess.postData("Visitor/save/visitorInfo", data);
      return result;
    };
}
export default new VisitorDataAccess();