import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { filterOptions } from "src/common/constants";
import procedureJobDataAccess from "src/dataAccess/procedures/procedureJobDataAccess";
import productbatchcodedataaccess from "src/dataAccess/products/productbatchcodedataaccess";

interface IListType {
  pageNo: number;
  pageSize: number;
  sortData: string;
  filters: any[];
}
interface jobListType extends IListType {
  typeProcedure: number;
  isArchived: boolean;
  hasDiscrepancies?: boolean;
}

export const loadingProcedureJob = createAction<boolean>(
  "[Procedure Job] Loading procedure Job list"
);

export const getListActionSuccess = createAction<any>(
  "[Procedure Job] Get Procdure Job list Success"
);

export const getListActionAsync = createAsyncThunk<string, jobListType>(
  "[Procedure Job] Get Procedure Job list",
  async (parameter: jobListType, thunkAPI) => {
    thunkAPI.dispatch(loadingProcedureJob(true));

    var filter = {
      propertyName: "TypeProcedure",
      value: parameter.typeProcedure.toString(),
      type: filterOptions.equals.value,
    };
    var filterIsArchied = {
      propertyName: "IsArchived",
      value: parameter.isArchived.toString(),
      type: filterOptions.equals.value,
    };

    parameter.filters = parameter.filters.concat(filter);
    parameter.filters = parameter.filters.concat(filterIsArchied);
    if (parameter.hasDiscrepancies) {
      var filterHasDiscrepancies = {
        propertyName: "HasDiscrepancies",
        value: parameter.hasDiscrepancies.toString(),
        type: filterOptions.equals.value,
      };
      parameter.filters = parameter.filters.concat(filterHasDiscrepancies);
    }
    const result = await procedureJobDataAccess
      .getList(parameter)
      .then((res) => {
        thunkAPI.dispatch(loadingProcedureJob(false));
        thunkAPI.dispatch(getListActionSuccess(res));
        return res;
      });
    return result;
  }
);
// export const getQurantineListSuccess = createAction<any>(
//   "[Qurantine] Get Qurantine List Success"
// );
// export const loadingList = createAction<boolean>("[Qurantine] Loading list");
// export const GetQurantineList = createAsyncThunk<any, IListType>(
//   "[Qurantine] Get Qurantine List",
//   async (parameter, thunkAPI) => {
//     thunkAPI.dispatch(loadingList(true));
//     const result = await productbatchcodedataaccess.getData(parameter).then((res) => {
//       console.log(res.data);
//       thunkAPI.dispatch(getQurantineListSuccess(res));
//       thunkAPI.dispatch(loadingList(false));
//       return res;
//     });
//     return result;
//   }
// );

