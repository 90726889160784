import { createReducer } from "@reduxjs/toolkit";
//import { DeletePurchaseOrderSuccess, GetPOListSuccess, LoadingData } from "./poAction";
import {
  AddPODetails,
  GetPOListSuccess,
  GetProductDetails,
  LoadingData,
  RemovePODetails,
  SetPODetails,
  DeletePurchaseOrderSuccess,
  GetPurchaseOrdersCount,
} from "./poAction";
export interface PurchaseOrderCount {
  CountAll: number;
  CountOutstanding: number;
  CountInProgress: number;
  CountCompleted: number;
  CountDeleted: number;
  CountOverdue: number;
}
export interface IPOState {
  productDetails: any[];
  purchaseOrders?: any[];
  dataCount: number;
  isLoading?: boolean;
  PurchaseOrderCount: Partial<PurchaseOrderCount>;
}
const initialState: IPOState = {
  purchaseOrders: [],
  productDetails: [],
  dataCount: 0,
  PurchaseOrderCount: {},
};

export const purchaseOrderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LoadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(GetPOListSuccess, (state, action) => {
      state = {
        ...state,
        purchaseOrders: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(DeletePurchaseOrderSuccess, (state, action) => {
      let filteredData = state.purchaseOrders.filter(
        (item) => !action.payload.includes(item.id)
      );
      state = {
        ...state,
        purchaseOrders: filteredData,
      };
      return state;
    })
    .addCase(GetPurchaseOrdersCount.fulfilled, (state, action) => {
      state = {
        ...state,
        PurchaseOrderCount: {
          CountAll: action?.payload?.countAll,
          CountOutstanding: action?.payload?.countOutstanding,
          CountInProgress: action?.payload?.countInProgress,
          CountCompleted: action?.payload?.countCompleted,
          CountDeleted: action?.payload?.countDeleted,
          CountOverdue: action?.payload?.countOverdue,
        },
      };
      return state;
    })
    .addCase(SetPODetails, (state, action) => {
      console.log(action.payload);
      let data = (action.payload ?? []).map((item, index) => {
        let cpy = { ...item };
        cpy["key"] = index + 1;
        return cpy;
      });
      console.log(data);
      state = {
        ...state,
        productDetails: data,
      };
      return state;
    })
    .addCase(AddPODetails, (state, action) => {
      let update = {
        ...action.payload,
        key: (state.productDetails ?? [])?.length + 1,
      };
      console.log(update);
      state = {
        ...state,
        productDetails: (state?.productDetails ?? []).concat(update),
      };
      return state;
    })
    .addCase(RemovePODetails, (state, action) => {
      let filter = state.productDetails.filter(
        (item) => item.key !== action.payload.key
      );
      state = {
        ...state,
        productDetails: filter,
      };
      return state;
    })
    .addCase(GetProductDetails.pending, (state, action) => {
      state = {
        ...state,
        isLoading: true,
      };
      return state;
    })
    .addCase(GetProductDetails.fulfilled, (state, action) => {
      let data = (action.payload ?? []).map((item, index) => {
        item.key = index;
        return item;
      });
      state = {
        ...state,
        productDetails: data,
        isLoading: false,
      };
      return state;
    });
});
