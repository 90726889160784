import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import purchaseOrderDataAccess from "src/dataAccess/purchaseOrders/purchaseOrderDataAccess";

export const LoadingData = createAction<boolean>("[PO] Loading data");
export const GetPOListSuccess = createAction<any>(
  "[PO] Get all PO list success"
);
export const DeletePurchaseOrderSuccess = createAction<string[]>(
  "[PO] Delete PO success"
);
interface poListType extends IListType {
  potype: string;
}

export const AddPODetails = createAction<any>("Add PODetails Success");
export const RemovePODetails = createAction<any>("Remove PODetails success");
export const SetPODetails = createAction<any[]>("[PO] set PODetails");
export const GetPOList = createAsyncThunk<any, IListType>(
  "[PO] Get PO List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await purchaseOrderDataAccess
      .getAll(parameter)
      .then((res) => {
        thunkAPI.dispatch(GetPOListSuccess(res));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);
export const GetProductDetails = createAsyncThunk<any, number>(
  "Get Purchase Order Details ",
  async (parameter: number) => {
    const result = await purchaseOrderDataAccess
      .GetProductDetails(parameter)
      .then((res) => res?.data);
    console.log("result");
    console.log(result);
    return result;
  }
);
export const GetPurchaseOrdersCount = createAsyncThunk(
  "[PO] Get Purchase Order count by type",
  async () => {
    const result = await purchaseOrderDataAccess
      .getPurchaseOrdersCount()
      .then((res) => res?.data);
    console.log(result);
    return result;
  }
);
